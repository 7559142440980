import React from 'react';
import './projects.css';
import { useLang } from '../LangContext';

function Projects() {
  const projectsInfo = [
    {
      nameEn: 'Find them all!',
      nameRu: 'Найди их всех!',
      descriptionEn:
        'This is a very entertaining game of finding cartoon characters in a huge image. Get them all in the shortest time possible.',
      descriptionRu:
        'Это очень увлекательная игра по поиску персонажей мультфильмов на огромном изображении. Найдите из всех за минимальное время.',
      technologies: 'HTML, CSS, React, React Router, Firebase',
      live: 'https://iamevgen.github.io/photo-tagging-app/',
      code: 'https://github.com/iamEvgen/photo-tagging-app',
      video: 'project-video/photo-tagging-app.mp4',
      id: 0,
    },
    {
      nameEn: 'Store with game posters',
      nameRu: 'Магазин игровых постеров',
      descriptionEn:
        'An e-commerce web application which allows users to add items to their cart, edit item quantities, and remove items.',
      descriptionRu:
        'Веб-приложение для электронной коммерции, которое позволяет пользователям добавлять товары в корзину, редактировать количество товаров и удалять их из корзины.',
      technologies: 'HTML, CSS, React, React Router',
      live: 'https://iamevgen.github.io/shopping-cart/',
      code: 'https://github.com/iamEvgen/shopping-cart',
      video: 'project-video/shopping-cart.mp4',
      id: 1,
    },
    {
      nameEn: 'Heroes in the Witcher',
      nameRu: 'Герои Ведьмака',
      descriptionEn:
        'A fun game for memory training. You have to click on characters to earn points, but only once per character.',
      descriptionRu:
        'Веселая игра для тренировки памяти. Вам нужно нажимать на персонажей, чтобы заработать очки, но только один раз на каждого персонажа.',
      technologies: 'HTML, CSS, React',
      live: 'https://iamevgen.github.io/memory-card/',
      code: 'https://github.com/iamEvgen/memory-card',
      video: 'project-video/memory-game.mp4',
      id: 2,
    },
    {
      nameEn: 'CV Creator',
      nameRu: 'Генератор резюме',
      descriptionEn: 'A web application for creating a personal CV.',
      descriptionRu: 'Веб-приложение для создания персонального резюме.',
      technologies: 'HTML, CSS, React',
      live: 'https://iamevgen.github.io/cv-project/',
      code: 'https://github.com/iamEvgen/cv-project',
      video: 'project-video/cv-creator.mp4',
      id: 3,
    },
    {
      nameEn: 'Battleship',
      nameRu: 'Морской бой',
      descriptionEn:
        'The classic Battleship game with AI inside. Try to beat him.',
      descriptionRu:
        'Классическая игра "Морской бой" с умным противником. Попробуйте победить его, я постарался сделать так, чтобы это было непросто.',
      technologies: 'HTML, CSS, vanilla JavaScript, Webpack, Jest',
      live: 'https://iamevgen.github.io/battleship/',
      code: 'https://github.com/iamEvgen/battleship',
      video: 'project-video/battleship.mp4',
      id: 4,
    },
    {
      nameEn: 'Weather app',
      nameRu: 'Приложение погоды',
      descriptionEn: 'Simple and beautiful weather app.',
      descriptionRu: 'Простое и симпатичное приложение погоды.',
      technologies: 'HTML, CSS, vanilla JavaScript, OpenWeatherMap API',
      live: 'https://iamevgen.github.io/weather-app/',
      code: 'https://github.com/iamEvgen/weather-app',
      video: 'project-video/weather-app.mp4',
      id: 5,
    },
    {
      nameEn: 'ToDo List',
      nameRu: 'Список дел',
      descriptionEn:
        'A classic and very useful application ToDo list. Create projects and todos, set the date, add to favorites.',
      descriptionRu:
        'Классическое и очень полезное приложение "Список дел". Создавайте проекты и дела, устанавливайте дату, добавляйте в избранное.',
      technologies: 'HTML, CSS, vanilla JavaScript, localStorage, Webpack',
      live: 'https://iamevgen.github.io/todo-list/',
      code: 'https://github.com/iamEvgen/todo-list',
      video: 'project-video/todo-list.mp4',
      id: 6,
    },
    {
      nameEn: 'Tic Tac Toe',
      nameRu: 'Крестики-нолики',
      descriptionEn:
        'The world-famous game of tic-tac-toe with powerful AI inside.',
      descriptionRu:
        'Всемирно известная игра "Крестики-нолики" с мощным искусственным интеллектом внутри.',
      technologies: 'HTML, CSS, vanilla JavaScript',
      live: 'iamevgen.github.io/tic-tac-toe/',
      code: 'https://github.com/iamEvgen/tic-tac-toe',
      video: 'project-video/tic-tac-toe.mp4',
      id: 7,
    },
    {
      nameEn: 'Library',
      nameRu: 'Библиотека',
      descriptionEn: 'A web application to keep a reading list.',
      descriptionRu: 'Веб-приложение для хранения списка книг.',
      technologies: 'HTML, CSS, vanilla JavaScript',
      live: 'https://iamevgen.github.io/library/',
      code: 'https://github.com/iamEvgen/library',
      video: 'project-video/library.mp4',
      id: 8,
    },
    {
      nameEn: 'Calculator',
      nameRu: 'Калькулятор',
      descriptionEn: 'My vesion of calculator using JS.',
      descriptionRu: 'Моя версия калькулятора на чистом JS.',
      technologies: 'HTML, CSS, vanilla JavaScript',
      live: 'https://iamevgen.github.io/calculator/',
      code: 'https://github.com/iamEvgen/calculator',
      video: 'project-video/calculator.mp4',
      id: 9,
    },
  ];

  const { ruLang } = useLang();
  const translation = {
    demoBtn: ruLang ? 'демо' : 'demo',
    codeBtn: ruLang ? 'код' : 'code',
    title: ruLang ? 'проекты' : 'projects',
    technologies: ruLang ? 'Построено на' : 'Built using',
  };

  const projectBoxes = projectsInfo.map((project) => {
    const video = require(`../${project.video}`);

    return (
      <div key={project.id} className="project-box">
        <div className="project-info">
          <div className="project-title">
            <h3>{ruLang ? project.nameRu : project.nameEn}</h3>
            <h3 className="shadow">
              {ruLang ? project.nameRu : project.nameEn}
            </h3>
          </div>
          <div className="project-description-text">
            {ruLang ? project.descriptionRu : project.descriptionEn}
          </div>
          <div className="project-description-text">
            {`${translation.technologies} ${project.technologies}.`}
          </div>
          <div className="project-buttons">
            <a href={project.live}>
              <button className="project-button">{translation.demoBtn}</button>
            </a>
            <a href={project.code}>
              <button className="project-button">{translation.codeBtn}</button>
            </a>
          </div>
        </div>
        <div className="project-video">
          <video playsInline autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  });

  return (
    <div className='project100'>
      <div id="projects" className="projects section">
        <div className="title">
          <h2>{translation.title}</h2>
          <h2 className="shadow">{translation.title}</h2>
        </div>
        {projectBoxes}
      </div>
    </div>
  );
}

export default Projects;
