import React from 'react';
import './home.css';
import { useLang } from '../LangContext';

function Home() {
  const arrows = require('../icons/arrows.png');
  const download = require('../icons/download.png');

  const [rotate, setRotate] = React.useState({ xDegrees: 0, yDegrees: 0 });
  const { ruLang, toggleLang } = useLang();

  function countRotation(event) {
    const rotation = 10;
    const rotateY =
      (rotation - (rotation * event.clientX * 2) / event.view.innerWidth) * -1;
    const rotateX =
      rotation - (rotation * event.clientY * 2) / event.view.innerHeight;
    setRotate({ xDegrees: rotateX, yDegrees: rotateY });
  }

  function smoothScroll(event, id) {
    event.preventDefault();
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  const rotateStyle = {
    transform: `perspective(1000px) rotateX(${rotate.xDegrees}deg) rotateY(${rotate.yDegrees}deg) translateZ(100px)`,
  };

  const translation = {
    greeting1: ruLang ? 'Привет, я' : "Hi, I'm",
    name: ruLang ? 'Евгений Сергеев' : 'Evgeny Sergeev',
    greeting2: ruLang ? 'Я Frontend-разработчик' : "I'm a Frontend developer",
    infoBtn: ruLang ? 'Портфолио' : 'Portfolio',
    resumeBtn: ruLang ? 'Резюме (pdf)' : 'Resume (pdf)',
  };

  return (
    <div id="home" onMouseMove={countRotation} className="home">
      <div className="rotatable" style={rotateStyle}>
        <div>
          {translation.greeting1}{' '}
          <span className="home-my-name">{translation.name}.</span>
        </div>
        <div>{translation.greeting2}.</div>
        <div className="home-buttons">
          <a
            href="#about"
            onClick={(event) => {
              smoothScroll(event, 'about');
            }}
          >
            <button className="home-button">
              {translation.infoBtn + ' '}
              <img className="home-button-icon" src={arrows} alt="arrows" />
            </button>
          </a>
          <a href="https://iamevgen.ru/Evgeny-Sergeev-Resume-Ru.pdf">
            <button className="home-button">
              {translation.resumeBtn}
              <img
                className="home-button-icon"
                src={download}
                alt="external link"
              />
            </button>
          </a>
        </div>
      </div>
      <div onClick={toggleLang} className="lang-toggle">
        <span style={ruLang ? { color: 'rgb(211, 72, 72)' } : {}}>Ру </span>/
        <span style={ruLang ? {} : { color: 'rgb(211, 72, 72)' }}> En</span>
      </div>
    </div>
  );
}

export default Home;
