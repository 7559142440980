import React, { useState } from 'react';
import './contacts.css';
import { useLang } from '../LangContext';

function Contact() {
  const [showMessageSend, setShowMessageSend] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  function showMessage() {
    setShowMessageSend(true);
    setTimeout(() => {
      setShowMessageSend(false);
    }, 4000);
  }

  async function sendEmail() {
    await fetch('https://iamevgen.ru/send.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(formData),
    })
      .then(() => {
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        showMessage();
      })
      .catch((err) => {
        console.error(err);
        alert(
          'An error occurred while sending the message. More details in the logs.'
        );
      });
  }

  const github = require('../icons/github.png');
  const telegram = require('../icons/telegram.png');
  const linkedin = require('../icons/linkedin.png');

  const { ruLang } = useLang();
  const translation = {
    title: ruLang ? 'контакты' : 'contact',
    callToWrite: ruLang
      ? 'Не стесняйтесь писать мне через форму ниже:'
      : 'Feel free to send me a message using the form below:',
    name: ruLang ? 'Имя' : 'Name',
    email: ruLang ? 'Электронная почта' : 'Email',
    message: ruLang ? 'Сообщение' : 'Message',
    send: ruLang ? 'Отправить' : 'Send',
    textForLinks: ruLang
      ? 'Вы также можете найти меня по этим ссылкам:'
      : 'Уou can also find me using these links:',
  };

  return (
    <div id="contact" className="section">
      <div className="contact-box">
        <div className="title">
          <h2>{translation.title}</h2>
          <h2 className="shadow">{translation.title}</h2>
        </div>
        <div className="contact-text">{translation.callToWrite}</div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            sendEmail();
          }}
        >
          <input
            className="contact-form-input"
            type="text"
            placeholder={translation.name}
            required
            name="name"
            onChange={handleChange}
            value={formData.name}
          />
          <input
            className="contact-form-input"
            type="email"
            placeholder={translation.email}
            name="email"
            required
            onChange={handleChange}
            value={formData.email}
          />
          <textarea
            className="contact-form-input"
            placeholder={translation.message}
            name="message"
            rows={5}
            required
            onChange={handleChange}
            value={formData.message}
          />
          <button className="project-button">{translation.send}</button>
        </form>
        {showMessageSend && (
          <div className="message-send">The message was sent successfully!</div>
        )}

        <div className="contact-text">{translation.textForLinks}</div>

        <div className="contact-links">
          <div className="contact-link airplane">
            <a href="https://t.me/iam_evgen">
              <img src={telegram} alt="Telegram logo" />
            </a>
          </div>
          <div className="contact-link rotator">
            <a href="https://github.com/iamevgen">
              <img src={github} alt="Github logo" />
            </a>
          </div>
          <div className="contact-link rotator">
            <a href="https://www.linkedin.com/in/iamevgen/">
              <img src={linkedin} alt="LinkedIn logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
